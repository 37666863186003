/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* eslint-disable react/boolean-prop-naming */
/* eslint-disable max-lines */
/* eslint-disable spaced-comment,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */

import { FIELD_DATE_TYPE } from '@scandipwa/scandipwa/src/component/FieldDate/FieldDate.config';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Select from 'react-select';

import DatePicker from 'Component/DatePicker';
import Field from 'Component/Field';
import FieldFile from 'Component/FieldFile';
import { FieldNumberContainer } from 'Component/FieldNumber/FieldNumber.container';
import FieldSelectContainer from 'Component/FieldSelect/FieldSelect.container';
import EyeIcon from 'Component/Icons/EyeIcon';
import { MixType } from 'Type/Common.type';
import {
    EventsType,
    FieldAttrType,
    LabelType,
    OptionType
} from 'Type/Field.type';
import { noopFn } from 'Util/Common';

import { FIELD_TYPE } from './Field.config';

import './Field.style';
/**
 * Field
 * @class Field
 * @namespace MasafiFrontend/Component/Field/Component */
export class FieldComponent extends PureComponent {
    static propTypes = {
        // Field attributes
        type: PropTypes.oneOf(Object.values(FIELD_TYPE)).isRequired,
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        setRef: PropTypes.func.isRequired,
        mix: MixType.isRequired,
        options: PropTypes.arrayOf(OptionType).isRequired,
        changeValueOnDoubleClick: PropTypes.bool,
        updateSelectedValues: PropTypes.func,
        filterDate: PropTypes.func,

        // Validation
        showErrorAsLabel: PropTypes.bool.isRequired,
        validationResponse: (props, propName, componentName) => {
            const propValue = props[propName];

            if (propValue === null) {
                return;
            }

            if (typeof propValue === 'boolean') {
                return;
            }

            if (
                typeof propValue === 'object'
                && !Object.keys(propValue).includes('errorMessages')
            ) {
                throw new Error(
                    // eslint-disable-next-line max-len
                    `${componentName} only accepts null, bool or object of "errorMessages" as "validationResponse", received "${JSON.stringify(
                        propValue
                    )}"`
                );
            }
        },

        // Labels
        label: LabelType.isRequired,
        subLabel: PropTypes.string.isRequired,
        addRequiredTag: PropTypes.bool.isRequired
    };

    static defaultProps = {
        validationResponse: null,
        changeValueOnDoubleClick: false,
        updateSelectedValues: null,
        filterDate: null
    };

    hidePass = this.hidePass.bind(this);

    showPass = this.showPass.bind(this);

    renderMap = {
        // Checkboxes & Radio
        [FIELD_TYPE.radio]: this.renderCheckboxOrRadio.bind(this),
        [FIELD_TYPE.checkbox]: this.renderCheckboxOrRadio.bind(this),
        [FIELD_TYPE.multi]: this.renderCheckboxOrRadio.bind(this),

        // Default input
        [FIELD_TYPE.email]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.text]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.time]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.dateTime]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.date]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.password]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.submit]: this.renderDefaultInput.bind(this),

        // Custom fields
        [FIELD_TYPE.file]: this.renderFile.bind(this),
        [FIELD_TYPE.select]: this.renderSelect.bind(this),
        [FIELD_TYPE.textarea]: this.renderTextArea.bind(this),
        [FIELD_TYPE.button]: this.renderButton.bind(this),
        [FIELD_TYPE.number]: this.renderNumber.bind(this),
        [FIELD_TYPE.datePicker]: this.renderDatePicker.bind(this)
    };

    //#region INPUT TYPE RENDER
    renderDefaultInput() {
        const {
            type, setRef, attr, events, isDisabled
        } = this.props;

        const renderPasswordBtn = type === 'password';
        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ type }
                  { ...attr }
                  { ...events }
                />
                { renderPasswordBtn ? (
                    <button
                      type="button"
                      block="Field"
                      elem="TogglePasswordBtn"
                      onMouseDown={ this.showPass }
                      onMouseUp={ this.hidePass }
                      onTouchStart={ this.showPass }
                      onTouchEnd={ this.hidePass }
                    >
                        <EyeIcon />
                    </button>
                ) : null }
            </>
        );
    }

    renderDatePicker() {
        const {
            setRef, attr
        } = this.props;

        const { filterDate, updateSelectedValues } = attr;

        return (
            <DatePicker
              setRef={ setRef }
              type={ FIELD_DATE_TYPE.date }
              isRequired
              updateSelectedValues={ updateSelectedValues }
              popperPlacement="top"
              minDate={ new Date() }
              filterDate={ filterDate }
              { ...attr }
            />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    showPass() {
        const {
            attr: { id }
        } = this.props;

        const passwordField = document.getElementById(id);
        passwordField.type = 'text';
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    hidePass() {
        const {
            attr: { id }
        } = this.props;

        const passwordField = document.getElementById(id);
        passwordField.type = 'password';
    }

    renderFile() {
        const { attr, events, setRef } = this.props;

        return <FieldFile attr={ attr } events={ events } setRef={ setRef } />;
    }

    renderNumber() {
        const {
            attr, events, setRef, isDisabled = false
        } = this.props;

        return (
            <FieldNumberContainer
              attr={ attr }
              events={ events }
              setRef={ setRef }
              isDisabled={ isDisabled }
            />
        );
    }

    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick
        } = this.props;

        const {
            searchselect, selectPlaceholder,
            jit,
            id,
            value,
            landmarkErrorEvent,
            landmarkEvenChange
        } = attr;

        if (searchselect) {
            return (
                <div>
                <Select
                  id={ id }
                  isRequired={ false }
                  isSearchable
                  value={ value }
                  defaultValue={ value }
                  options={ options }
                  name="landmark_area_id"
                  onChange={ jit }
                  placeholder={ selectPlaceholder }
                  isDisabled={ isDisabled }
                  className={ (landmarkErrorEvent && landmarkEvenChange) ? 'error-class' : '' }
                />
                { this.renderErrorMessageSearchSelect(landmarkErrorEvent, landmarkEvenChange) }
                </div>

            );
        }

        return (
            <FieldSelectContainer
              attr={ attr }
              events={ events }
              options={ options }
              setRef={ setRef }
              isDisabled={ isDisabled }
              changeValueOnDoubleClick={ changeValueOnDoubleClick }
            />
        );
    }

    renderErrorMessageSearchSelect(landmarkEventError, landmarkEvenChange) {
        if (landmarkEventError && landmarkEvenChange) {
            window.scrollTo({
                top: 1200,
                left: 0,
                behavior: 'smooth'
            });

            return (
                <div className="Field-ErrorMessages">
                    <div className="Field-ErrorMessage">This field is required!</div>
                </div>
            );
        }

        return null;
    }

    renderButton() {
        const {
            setRef, attr, events, isDisabled
        } = this.props;
        const { value = __('Submit') } = attr;
        return (
            <button
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              { ...attr }
              { ...events }
            >
                { value }
            </button>
        );
    }

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr,
            attr: { id = '' } = {},
            events: { onChange },
            events,
            isDisabled,
            label
        } = this.props;

        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };

        if (Object.prototype.hasOwnProperty.call(attr, 'checked')) {
            // eslint-disable-next-line fp/no-delete
            delete attr.defaultChecked;
        }

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ type }
                  { ...attr }
                  { ...inputEvents }
                />
                <div block="input-control" />
                { label }
            </label>
        );
    }

    renderTextArea() {
        const {
            setRef, attr, events, isDisabled
        } = this.props;

        return (
            <textarea
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              { ...attr }
              { ...events }
            />
        );
    }
    //#endregion

    //#region LABEL/TEXT RENDER
    // Renders validation error messages under field
    renderErrorMessage(message, key) {
        return (
            <div block="Field" elem="ErrorMessage" key={ key }>
                { message }
            </div>
        );
    }

    renderErrorMessages() {
        const {
            showErrorAsLabel,
            validationResponse,
            attr: { name }
        } = this.props;

        if (
            !showErrorAsLabel
            || !validationResponse
            || validationResponse === true
        ) {
            return null;
        }

        const { errorMessages } = validationResponse;

        if (!errorMessages) {
            return null;
        }

        return (
            <div block="Field" elem="ErrorMessages">
                { errorMessages.map((message, index) => this.renderErrorMessage.call(this, message, name + index)) }
            </div>
        );
    }

    // Renders fields label above field
    renderLabel() {
        const { type, label, attr: { name } = {} } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label
                  block="Field"
                  elem="Label"
                  htmlFor={ name || `input-${type}` }
                >
                    { label }
                    { this.renderRequiredTag() }
                </label>
            </div>
        );
    }

    // Renders * for required fields
    renderRequiredTag() {
        const { addRequiredTag } = this.props;

        if (!addRequiredTag) {
            return null;
        }

        return (
            <span block="Field" elem="Label" mods={ { isRequired: true } }>
                { ' *' }
            </span>
        );
    }

    // Renders fields label under field
    renderSubLabel() {
        const { subLabel } = this.props;

        if (!subLabel) {
            return null;
        }

        return (
            <div block="Field" elem="SubLabelContainer">
                <div block="Field" elem="SubLabel">
                    { subLabel }
                </div>
            </div>
        );
    }
    //#endregion

    render() {
        const {
            type,
            validationResponse,
            mix,
            attr: { name = '', hasPrefix = false } = {}
        } = this.props;

        const prefix = '+971';
        const inputRenderer = this.renderMap[type];

        return (
            <div block="Field" elem="Wrapper" mods={ { type, name } }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: validationResponse === true,
                      hasError:
                            validationResponse !== true
                            && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >
                    { type !== FIELD_TYPE.checkbox
                        && type !== FIELD_TYPE.radio
                        && this.renderLabel() }
                    { hasPrefix ? (
                        <div block="Field" elem="PasswordWrapper">
                            <Field
                              type={ FIELD_TYPE.text }
                              attr={ {
                                  id: 'prefix',
                                  name: 'prefix',
                                  defaultValue: prefix,
                                  placeholder: __('Your phone code'),
                                  autocomplete: 'prefix'
                              } }
                              validateOn={ ['onChange'] }
                              isDisabled
                            />
                            { inputRenderer && inputRenderer() }
                        </div>
                    ) : null }

                    { inputRenderer && !hasPrefix && inputRenderer() }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
}

export default FieldComponent;
