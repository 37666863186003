/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import { Suspense } from 'react';

import SuccessIcon from 'Component/Icons/SuccessIcon';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ACCOUNT_REGISTRATION_URL } from 'Route/MyAccount/MyAccount.config';
import { CheckoutSuccess as SourceCheckoutSuccess } from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';
import { isSignedIn } from 'Util/Auth';
import { appendWithStoreCode } from 'Util/Url';

import './CheckoutSuccess.override.style';

/** @namespace MasafiFrontend/Component/CheckoutSuccess/Component */
export class CheckoutSuccessComponent extends SourceCheckoutSuccess {
    pushOrder = this.pushOrder.bind(this);

    renderCreateAccountButton() {
        const {
            isEmailAvailable,
            email,
            firstName,
            lastName
        } = this.props;

        if (!isEmailAvailable || isSignedIn()) {
            return null;
        }

        return (
            <div block="CheckoutRegistrationLink">
                <p block="CheckoutSuccess" elem="Description">
                    { __('Create an account to checkout faster next time and to re-order easily!') }
                </p>
                <Link
                  to={ {
                      pathname: appendWithStoreCode(`${ ACCOUNT_REGISTRATION_URL }`),
                      state: {
                          firstName,
                          lastName,
                          email
                      }
                  } }
                  block="Button"
                >
                    { __('Create account') }
                </Link>
            </div>
        );
    }

    renderButtons() {
        return (
            <div block="CheckoutSuccess" elem="ButtonWrapper">
                <button
                  block="Button"
                  onClick={ this.pushOrder }
                  mix={ { block: 'CheckoutSuccess', elem: 'ContinueButton' } }
                  to="/"
                >
                    { __('Continue shopping') }
                </button>
            </div>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    pushOrder() {
        window.location.href = '/';
    }

    renderMainContent() {
        const { email } = this.props;
        return (
            <div>
                <h3 block="CheckoutSuccess" elem="Heading">
                    { __('Payment Complete!') }
                </h3>
                <p block="CheckoutSuccess" elem="Description">
                    { __('Thank you, your payment has been successful. ') }

                    { __('A confirmation email has been sent to ') }
                    <span block="CheckoutSuccess" elem="CustomerEmail">
                        { email }
                    </span>
                </p>
            </div>
        );
    }

    renderHrefForSpinandWin() {
        const {
            isMobile, firstOrder, orderID, redirectUrlSpinWin
        } = this.props;

        const mainUrl = window.location.href.split('/');
        // eslint-disable-next-line max-len
        const iframeSrcUrl = `${mainUrl[0]}//${mainUrl[2]}/spin-win?orderId=${orderID}&customerId=${redirectUrlSpinWin}`;

        if (firstOrder) {
            const MobileWidth = 430;
            const DesktopWidth = 700;
            const widthIframe = (isMobile === true) ? MobileWidth : DesktopWidth;
            const redirectUrlSpinWinsSaid = iframeSrcUrl.trim();

            return (
                <Suspense fallback={ <Loader /> }>
                    <iframe
                      src={ redirectUrlSpinWinsSaid }
                      title="scratch_win"
                      className="scratchwin-iframe"
                      scrolling="no"
                      frameBorder="0"
                      border="0"
                      height={ widthIframe }
                      width="100%"
                    />
                </Suspense>
            );
        }

        return '';
    }

    render() {
        return (
            <div block="CheckoutSuccess">

                <div block="CheckoutSuccess" elem="MainContent">
                    <SuccessIcon />
                    { this.renderMainContent() }

                </div>

                { this.renderButtons() }

                { this.renderCreateAccountButton() }
            </div>
        );
    }
}

export default CheckoutSuccessComponent;
