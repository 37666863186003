/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import { CheckoutQuery as SourceCheckoutyQuery } from 'SourceQuery/Checkout.query';
import { Field } from 'Util/Query';

/** @namespace MasafiFrontend/Query/Checkout/Query */
export class CheckoutQuery extends SourceCheckoutyQuery {
    _getOrderField() {
        return new Field('order').addFieldList([
            'order_id',
            this._getCcavenuePayment()
        ]);
    }

    _getCcavenuePayment() {
        return new Field('ccavenue_payment').addFieldList([
            'url',
            this._getCcavenuePaymentFields(),
            this._getCcavenueApplePaymentFields()
        ]);
    }

    _getCcavenueApplePaymentFields() {
        return new Field('fields_apple_pay').addFieldList([
            'encRequest',
            'access_code'
        ]);
    }

    _getCcavenuePaymentFields() {
        return new Field('fields').addFieldList([
            'integration_type',
            'encRequest',
            'access_code'
        ]);
    }

    _getPaymentMethodFields() {
        return ['code', 'title', 'instruction'];
    }

    _getLandmarksFields() {
        return ['day', 'landmark_id', 'name'];
    }

    getLandmarks(regionId) {
        const query = new Field('getLandmarks')
            .addArgument('region_id', 'Int!', regionId)
            .addFieldList(this._getLandmarksFields());

        return query;
    }

    getSpinandWin(orderId) {
        const query = new Field('customerPlacedOrder')
            .addArgument('order_id', 'String', orderId)
            .addFieldList(['is_first_order', 'order_count', 'redirect_url', 'customer_id', 'success']);

        return query;
    }

    _getDeliveryDateField() {
        return new Field('order').addFieldList(['order_id']);
    }

    getSaveDeliveryDateMutation(orderId, selectedDate, landmarkId) {
        const mutation = new Field('saveDeliveryDate')
            .addArgument('order_id', 'String!', orderId)
            .addArgument(
                'delivery_date',
                'String!',
                new Date(selectedDate).toLocaleDateString('en-GB', {
                    timeZone: 'Asia/Dubai'
                })
            )
            .addArgument('landmark_area_id', 'String!', landmarkId);

        return mutation;
    }

    getCaptureOrderPlatform(orderId, platform) {
        const data = {
            order_number: orderId,
            platform
        };
        const mutation = new Field('orderPlatform')
            .addArgument('input', 'OrderPlatformInput!', data)
            .addFieldList(['message', 'success']);

        return mutation;
    }

    setSpinWinApplied(flagid = '1') {
        const data = {
            flag: flagid

        };
        const mutation = new Field('spinWinApplied')
            .addArgument('input', 'spinWinAppliedInput!', data)
            .addFieldList(['message', 'status']);

        return mutation;
    }

    getCardDetails() {
        const mutaion = new Field('storeConfig')
            .addFieldList(['settings_checkoutcom_public_key',
                'apple_checkoutcom_merchant_id', 'settings_checkoutcom_public_key', 'apple_checkoutcom_enable']);

        return mutaion;
    }

    getCheckoutComPlaceOrder(data) {
        const mutation = new Field('placeOrderWithCheckout')
            .addArgument('input', 'PlaceOrderWithCheckoutInput', data)
            .addFieldList(['message', 'redirect_link', 'status', 'type']);

        return mutation;
    }

    getCheckoutComApplePayPlaceOrder(version, data, signature, ephemeralPublicKey, publicKeyHash, transactionId) {
        const dataTosend = {
            version, data, signature, ephemeralPublicKey, publicKeyHash, transactionId
        };
        const mutation = new Field('applePayPlaceOrder')
            .addArgument('input', 'applePayPlaceOrderInput', dataTosend);
        //  .addFieldList('applePayPlaceOrder');

        return mutation;
    }

    getCheckoutComApplePayPlaceOrderBitcash(rechargeAmount, bonus, version, data, signature,
        ephemeralPublicKey, publicKeyHash, transactionId) {
        const dataTosend = {
            rechargeAmount, bonus, version, data, signature, ephemeralPublicKey, publicKeyHash, transactionId
        };
        const mutation = new Field('appleBitcashRecharge')
            .addArgument('input', 'appleBitcashRechargeInput', dataTosend)
            .addFieldList(['status', 'redirect_link']);

        return mutation;
    }

    getAppleStoreData(apple_email) {
        const data = {
            real_cust_email: apple_email
        };
        const mutation = new Field('iosCustEmail')
            .addArgument('input', 'IosCustEmailInput!', data)
            .addFieldList(['message', 'success']);

        return mutation;
    }

    validateURlWebhook(url) {
        const query = new Field('applePayValidation')
            .addArgument('url', 'String!', url)
            .addFieldList(['result']);

        return query;
    }

    startSubscriptionMutation(orderNumber, startDate, interval) {
        const data = {
            order_id: orderNumber,
            start_date: startDate,
            interval,
            end_date: ''

        };
        const mutation = new Field('subscriptionPost')
            .addArgument('input', 'subscriptionPostInput', data)
            .addFieldList(['message', 'status']);

        return mutation;
    }

    saveCardDetailsMutation(value) {
        const data = {
            token: value

        };
        const mutation = new Field('saveCard')
            .addArgument('input', 'SaveCardInput', data)
            .addFieldList(['message', 'status']);

        return mutation;
    }

    getSaveCardDetails() {
        const query = new Field('getCard').addFieldList(['status', 'data']);
        return query;
    }
}

export default new CheckoutQuery();
